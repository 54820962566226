nav.MuiPagination-root > ul > li > button.Mui-selected {
  color: white !important;
  border: 1px solid #17479D !important;
  background-color: #17479D !important;
}

.pop-upward-menu {
  position: absolute;
  left: 125px;
  bottom: 0;
}

.nav-option {
  height: 80px;
  line-height: 80px;
}