.carousel .slide img {
  width: auto !important;
}

.image-grid-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* This targets the image-grid-item parent to change its witdh and space it out evenly */
div.image-grid > div > div > div {
  width: 31.33%;
  margin: 1%;
}